import React, { Component } from "react";
import Loader from "react-loader";
import TableComponent from "./common/TableComponent";
import moment from "moment";
import DateBox from "./controls/DateBox";
import Transition from "./common/Transition";
import * as constants from "../constants";
import { DateFormatter } from "./common/DateFormatter";
import { AgentsRequests } from "../apiRequests/AgentsRequests";
import MultiSelectBox from "./controls/MultiSelectBox";
import SearchBox from "./controls/SearchBox";
import { currencyFormat, multiselectFilter } from "./common/commonFns";

const dateFormatter = new DateFormatter();
const agentsRequests = new AgentsRequests();

class ActiveEnrolments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hidden: "hidden",
      allPrograms: [],
      allCampuses: [],
      allEnrolmentNo: [],
      programsSelectedList: [],
      campusSelectedList: [],
      enrolmentNoSelectedList: [],
      isLoaded: true,
      agentId: localStorage.getItem("agentId"),
      tableRows: [],
      tableEmpty: "hidden",
      firstRowIndex: 0,
      lastRowIndex: constants.NUMBEROFROWS - 1,
      filterOn: false,
      dataLength: 0,
      searchFieldValue: "",
      isOpen: false,
      expanded: "",
      background: "hidden",
      startDate: "",
      endDate: "",
      enrolmentsData: [],
    };
    this.setPageIndexFn = this.setPageIndexFn.bind(this);
    this.handleDateBox = this.handleDateBox.bind(this);
  }

  componentWillMount() {
    this.setState({
      hidden: "",
      isLoaded: false,
    });
    let startDate = localStorage.getItem("t5StartDate");
    let endDate = localStorage.getItem("t5EndDate");
    this.setState(
      {
        startDate: startDate,
        endDate: endDate,
      },
      function () {
        this.getEnrolmentCommission(startDate, endDate);
      }
    );
  }

  getEnrolmentCommission(startDate, endDate) {
    agentsRequests.enrolmentCommission(startDate, endDate).then((result) => {
      let commissionData = result;
      if (commissionData !== "error") {
        this.allActiveEnrolmentsFn(startDate, endDate, commissionData);
      }
    });
  }

  allActiveEnrolmentsFn(startDate, endDate, commissionData) {
    agentsRequests.allActiveEnrolments(startDate, endDate).then((response) => {
      let allEnrolments = response;
      let enrolmentsCommission = commissionData;
      if (allEnrolments !== "error") {
        let item = {};
        let combinedData = [];
        if (enrolmentsCommission) {
          allEnrolments.map((object) => {
            let a = enrolmentsCommission.find(
              (element) => element.enrolmentID == object.enrolmentID
            );

            if (a) {
              item.enrolmentNo = object.enrolmentNo;
              item.studentNo = object.studentNo;
              item.programName = object.programName;
              item.studentName = object.studentName;
              item.startDate = object.enrolmentStartDate;
              item.endDate = object.enrolmentEndDate;
              item.campus = object.campus;
              item.commission = a.totalCommission;
              combinedData.push({ ...item });
            } else {
              item.enrolmentNo = object.enrolmentNo;
              item.studentNo = object.studentNo;
              item.programName = object.programName;
              item.studentName = object.studentName;
              item.startDate = object.enrolmentStartDate;
              item.endDate = object.enrolmentEndDate;
              item.commission = 0;
              combinedData.push({ ...item });
            }
          });
        }
        //console.log(combinedData);
        this.setState(
          {
            enrolmentsData: combinedData,
            dataLength: combinedData.length,
          },
          function () {
            this.loadMultiSelectData();
          }
        );
      }
    });
  }

  loadMultiSelectData() {
    let allPrograms = [];
    let allCampuses = [];
    let allEnrolmentNo = [];

    let data = this.state.enrolmentsData;

    data.map((object) => {
      if (allPrograms.some((item) => object.programName === item)) {
      } else {
        allPrograms.push(object.programName);
      }
    });

    data.map((object) => {
      if (allCampuses.some((item) => object.campus === item)) {
      } else {
        allCampuses.push(object.campus);
      }
    });

    data.map((object) => {
      if (allEnrolmentNo.some((item) => object.enrolmentNo === item)) {
      } else {
        allEnrolmentNo.push(object.enrolmentNo);
      }
    });

    this.setState(
      {
        allPrograms: allPrograms,
        allCampuses: allCampuses,
        allEnrolmentNo: allEnrolmentNo,
      },
      function () {
        this.loadData();
      }
    );
  }

  loadData() {
    let tableRows = [];
    let rowItem = {};
    let data = this.state.enrolmentsData;
    let firstRowIndex = this.state.firstRowIndex;
    let lastRowIndex = this.state.lastRowIndex;

    data = this.searchItems(data);

    data = this.dataProcessor(data);

    let dataLength = data.length;
    if (dataLength < parseInt(firstRowIndex) + 1) {
      firstRowIndex = 0;
      lastRowIndex = constants.NUMBEROFROWS - 1;
    }

    if (data != "" && data != null) {
      data.map((object, index) => {
        if (index >= firstRowIndex && index <= lastRowIndex) {
          rowItem.ImgSrc =
            "https://ui-avatars.com/api/name=" +
            object.studentName +
            "/?background=random";
          //rowItem.PillTxt = object.enrolmentNo;
          // rowItem.PillClr = "green";
          rowItem.Label1 = object.studentName;
          rowItem.DescriptionClm1 = " Student Number: " + object.studentNo;
          rowItem.Label2 = object.programName;
          let startDate = moment(object.startDate, "DD/MM/YYYY").format(
            "DD-MM-YYYY"
          );
          let endDate = moment(object.endDate, "DD/MM/YYYY").format(
            "DD-MM-YYYY"
          );
          rowItem.Campus = object.campus;
          rowItem["Start Date"] = startDate;
          rowItem["End Date"] = endDate;
          rowItem["Enrolment Number"] = object.enrolmentNo;
          rowItem.Value = "$" + currencyFormat(object.commission);
          tableRows.push({ ...rowItem });
        }
      });
    } else {
      //following line will unhide Nodata View
      this.setState({ tableEmpty: "" });
    }

    this.setState({ tableRows: tableRows });

    this.setState({
      hidden: "hidden",
      isLoaded: true,
    });
  }

  dataProcessor(data) {
    let originalData = data;

    let programsSelectedList = this.state.programsSelectedList;
    let campusSelectedList = this.state.campusSelectedList;
    let enrolmentNoSelectedList = this.state.enrolmentNoSelectedList;

    if (programsSelectedList != "") {
      data = multiselectFilter(data, programsSelectedList, "programName");
    }
    if (campusSelectedList != "") {
      data = multiselectFilter(data, campusSelectedList, "campus");
    }
    if (enrolmentNoSelectedList != "") {
      data = multiselectFilter(data, enrolmentNoSelectedList, "enrolmentNo");
    }

    // following line of code will set dataLength
    this.setState({ dataLength: data.length });
    return data;
  }

  renderTable() {
    let tableHeadings = [
      "Student Details",
      "Program",
      "Enrolment Number",
      "Campus",
      "Start Date",
      "End Date",
      "Commission",
      "",
    ];
    let tableRows = this.state.tableRows;

    if (tableRows.length >= 1) {
      return (
        <TableComponent
          tableHeadings={tableHeadings}
          tableRows={tableRows}
          filterOn={this.state.filterOn}
          numberOfElementsPerPage={constants.NUMBEROFROWS}
          dataLength={this.state.dataLength}
          setPageIndexFn={this.setPageIndexFn}
          firstRowIndex={this.state.firstRowIndex}
          lastRowIndex={this.state.lastRowIndex}
          clmTwoWidth={"w-1/4"}
        />
      );
    } else {
      return (
        <div
          className={
            this.state.tableEmpty +
            " text-gray-600 w-full flex justify-center mt-20"
          }
        >
          <h1>
            There is No Data Available to Display, please reset filters if any
            applied.
          </h1>
        </div>
      );
    }
  }

  setPageIndexFn(firstRowIndex, lastRowIndex, btn) {
    this.setState(
      { firstRowIndex: firstRowIndex, lastRowIndex: lastRowIndex },
      function () {
        this.loadData();
      }
    );
  }

  searchHandeler() {
    var searchValue = document.getElementById("search").value;
    this.setState(
      {
        searchFieldValue: searchValue,
      },
      function () {
        this.loadData();
      }
    );
  }

  searchItems(data) {
    let originalData = data;
    let searchValue = this.state.searchFieldValue;
    if (searchValue != "") {
      data = data.filter((object) =>
        object.studentName.toUpperCase().includes(searchValue.toUpperCase())
      );
      this.setState({ filterOn: true });
      if (data.length === originalData.length) {
        this.setState({ filterOn: false });
      }
    }

    this.setState({ dataLength: data.length });
    return data;
  }

  handleDateBox(e, name) {
    let selectedDate = e;

    let fromDate = new Date(dateFormatter.formatterThree(this.state.startDate));
    let toDate = new Date(dateFormatter.formatterThree(this.state.endDate));
    let sDate = new Date(dateFormatter.formatterThree(selectedDate));
    localStorage.setItem("dateRangeLabel5", "Custom Range");
    if (name === "FromDate") {
      let startDate = dateFormatter.formatterTwo(sDate);
      let endDate = dateFormatter.formatterTwo(toDate);
      localStorage.setItem("t5StartDate", startDate);
      this.setState(
        {
          startDate: startDate,
          isOpen: false,
          expanded: "",
          background: "hidden",
        },
        function () {
          this.getEnrolmentCommission(startDate, endDate);
        }
      );
    } else if (name === "ToDate") {
      let startDate = dateFormatter.formatterTwo(fromDate);
      let endDate = dateFormatter.formatterTwo(sDate);
      localStorage.setItem("t5EndDate", endDate);
      this.setState(
        {
          endDate: endDate,
          isOpen: false,
          expanded: "",
          background: "hidden",
        },
        function () {
          this.getEnrolmentCommission(startDate, endDate);
        }
      );
    }
  }

  onMultiSelectChange(selectedList, item, id) {
    if (id === "program") {
      this.setState({
        programsSelectedList: selectedList,
      });
    } else if (id === "campus") {
      this.setState({
        campusSelectedList: selectedList,
      });
    } else if (id === "enrolmentNo") {
      this.setState({
        enrolmentNoSelectedList: selectedList,
      });
    }
    this.setState(
      {
        isOpen: false,
        expanded: "",
        background: "hidden",
      },
      function () {
        this.loadData();
      }
    );
  }

  filtersDropdown() {
    let expanded = this.state.expanded;
    if (expanded) {
      this.setState({
        isOpen: false,
        expanded: "",
        background: "hidden",
      });
    } else {
      this.setState({
        isOpen: true,
        expanded: "yes",
        background: "",
      });
    }
  }

  renderAdvancedSearch() {
    return (
      <div>
        <div className="md:mt-5 mt-1 flex xl:justify-end">
          <span
            onClick={() => this.filtersDropdown()}
            className="  p-2 h-7 rounded-md bg-white border border-gray-300 text-gray-700 hover:bg-gray-200 cursor-pointer"
          >
            Advanced Search
          </span>
        </div>
        <Transition
          show={this.state.isOpen}
          enter="transition ease-in duration-300 transform"
          enterFrom="opacity-25 scale-0"
          enterTo="opacity-100 scale-100"
          leave="transition ease-out duration-500 transform"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-25 scale-0"
        >
          <div className="origin-top right-0 absolute z-10 mb-8 w-full bg-gray-200">
            <div className="px-2 pt-4 lg:mt-0 flex-shrink-0 bg-gray-100 sm:rounded-lg border-b border-gray-200">
              <div className={"mx-4 mb-4 bg-gray-100"}>
                <div className="pb-4">
                  <div
                    className={" text-gray-600 w-full flex justify-center mb-4"}
                  >
                    <h1 className="font-semibold">Filters</h1>
                  </div>
                  <div className=" grid grid-cols-6 gap-6">
                    <div className="md:py-3 col-span-6 md:col-span-3 ">
                      <MultiSelectBox
                        id="program"
                        label="Select Programs"
                        selectedValues={this.state.programsSelectedList}
                        data={this.state.allPrograms}
                        onSelect={(selectedList, selectedItem) =>
                          this.onMultiSelectChange(
                            selectedList,
                            selectedItem,
                            "program"
                          )
                        }
                        onRemove={(selectedList, removedItem) =>
                          this.onMultiSelectChange(
                            selectedList,
                            removedItem,
                            "program"
                          )
                        }
                      />
                    </div>
                    <div className="md:py-3 col-span-6 md:col-span-3 ">
                      <MultiSelectBox
                        id="campus"
                        label="Select Campuses"
                        selectedValues={this.state.campusSelectedList}
                        onSelect={(selectedList, selectedItem) =>
                          this.onMultiSelectChange(
                            selectedList,
                            selectedItem,
                            "campus"
                          )
                        }
                        onRemove={(selectedList, removedItem) =>
                          this.onMultiSelectChange(
                            selectedList,
                            removedItem,
                            "campus"
                          )
                        }
                        data={this.state.allCampuses}
                        placeholder="Select"
                      />
                    </div>
                    <div className="md:py-3 col-span-6 md:col-span-3 ">
                      <MultiSelectBox
                        id="enrolmentNo"
                        label="Select Enrolment Number"
                        selectedValues={this.state.enrolmentNoSelectedList}
                        onSelect={(selectedList, selectedItem) =>
                          this.onMultiSelectChange(
                            selectedList,
                            selectedItem,
                            "enrolmentNo"
                          )
                        }
                        onRemove={(selectedList, removedItem) =>
                          this.onMultiSelectChange(
                            selectedList,
                            removedItem,
                            "enrolmentNo"
                          )
                        }
                        data={this.state.allEnrolmentNo}
                        placeholder="Select"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Transition>
        <div className="">
          <span
            onClick={() => this.filtersDropdown()}
            className={
              this.state.background +
              " z-6 fixed top-10 left-0 right-0 bottom-0 h-full w-full bg-gray-400 opacity-75"
            }
          />
        </div>
      </div>
    );
  }

  renderFiltersPanel(id) {
    const dateFormatter = new DateFormatter();
    let startDate = dateFormatter.formatter(this.state.fromDate);
    let endDate = dateFormatter.formatter(this.state.toDate);
    return (
      <div className="pb-4">
        <div className={" text-gray-600 w-full flex justify-center mb-4"}>
          <h1 className="font-semibold">Filters</h1>
        </div>
        <div className="pt-6 grid grid-cols-1 lg:grid-cols-4 gap-6">
          <div className=""></div>
          <div className=""></div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="relative mt-6 align-middle min-h-full">
        <span
          className={
            this.state.hidden +
            " fixed top-10 left-0 right-0 bottom-0 h-full w-full bg-white opacity-25"
          }
        />
        <Loader loaded={this.state.isLoaded}></Loader>
        <div className="flex rounded-md p-4 mb-4 md:mb-8 w-full grid grid-cols-1 gap-5 md:grid-cols-2 xl:grid-cols-6 bg-gray-100">
          <div className="xl:col-span-2">
            <SearchBox
              id={"search"}
              label={"Search For Student Name"}
              svgType={"student"}
              placeholder={"Name"}
              value={this.state.searchFieldValue}
              searchHandler={() => {
                this.searchHandeler();
              }}
            />
          </div>

          <div className="xl:col-span-1">
            <DateBox
              id={"Pd1"}
              label="Enrolment Start Date"
              onChange={this.handleDateBox}
              name={"FromDate"}
              value={this.state.startDate ? new Date(this.state.startDate) : ""}
            />
          </div>
          <div className="xl:col-span-1">
            <DateBox
              id={"Pd1"}
              label="Enrolment End Date"
              onChange={this.handleDateBox}
              name={"ToDate"}
              value={this.state.endDate ? new Date(this.state.endDate) : ""}
            />
          </div>
          <div className="xl:col-span-2">{this.renderAdvancedSearch()}</div>
        </div>

        <div>{this.renderTable()}</div>
      </div>
    );
  }
}

export default ActiveEnrolments;
