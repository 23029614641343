import React, { Component } from "react";
import "./assets/css/app.css";
import { Routes } from "./routes";
import AnswerQuiz from "./components/AnswerQuiz";
import ReactDOM from "react-dom";
// import Suburb from './components/common/Suburb';
// import WebForm from './components/WebForm-old';
// import TextBox from "./components/controls/TextBox";
// import SelectBox from "./components/controls/SelectBox";
// import CheckBox from "./components/controls/CheckBox";
// import DateBox from "./components/controls/DateBox";
// import MultiSelectBox from "./components/controls/MultiSelectBox";
// import FormSection from "./components/controlsnpm start/FormSection";
import AutoComplete from "./components/common/AutoComplete";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      appName: "AgentPortal",
      // formFields: {
      //   firstName: ""
      // }
    };
  }

  // handleChange = (e, name) => {
  //   const formFields = { ...this.state.formFields }
  //   if (name) formFields[name] = e
  //   else formFields[e.currentTarget.name] = e.currentTarget.value
  //   this.setState({ formFields: formFields })
  // }

  // handleCalChange = e => {
  //   const formFields = { ...this.state.formFields }
  //   formFields["startDate"] = e
  //   this.setState({ formFields: formFields })
  // }

  render() {
    // <component name={this.state.appName}>
    //this.props.name in the component
    // return <AutoComplete />;
    return <Routes />;
    // return <AnswerQuiz quizId={1} objectType={1} objectId={1} />
    // const countries = [
    //   { id: 1, value: "India" },
    //   { id: 2, value: "Australia" },
    //   { id: 3, value: "Canada" },

    // ]

    // const { formFields } = this.state

    // return (

    //   <form name='WebForm' methof='post'>
    //     <FormSection label="Personal Information">
    //       <TextBox name="firstName" label="First Name" value={formFields.firstName} onChange={this.handleChange} />
    //       <SelectBox data={countries} label="Country" name="country" value={formFields.country} onChange={this.handleChange} />
    //       <CheckBox name="Overseas" value={formFields.overseas} label="Is Student Overseas?" onChange={this.handleChange} />
    //       <DateBox label="Start Date" dateFormat="dd/MM/yyyy" name="startDate" onChange={this.handleChange} id="startDate" value={formFields.startDate} />
    //       <MultiSelectBox name="countries" data={countries} displayValue="value" onChange={this.handleChange} label="Countries" />
    //     </FormSection>

    //     <FormSection label="Personal Information">
    //       <TextBox name="firstName" label="First Name" value={formFields.firstName} onChange={this.handleChange} />
    //       <SelectBox data={countries} label="Country" name="country" value={formFields.country} onChange={this.handleChange} />
    //       <CheckBox name="Overseas" value={formFields.overseas} label="Is Student Overseas?" onChange={this.handleChange} />
    //       <DateBox label="Start Date" dateFormat="dd/MM/yyyy" name="startDate" onChange={this.handleChange} id="startDate" value={formFields.startDate} />
    //       <MultiSelectBox name="countries" data={countries} displayValue="value" onChange={this.handleChange} label="Countries" />
    //     </FormSection>
    //   </form >

    // )
  }
}

export default App;
