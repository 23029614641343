import React, { Component } from 'react'

export default class Modal extends Component {
    render() {
        const { modalState, headingIcon, headingText, buttonsList, children } = this.props;

        console.log(buttonsList)
        if (!buttonsList)
            return ""

        return (
            <div className={"fixed bottom-0 inset-x-0 px-4 pb-6 sm:inset-0 sm:p-0 " + (modalState ? "sm:flex" : "hidden") + " sm:items-center sm:justify-center"}>
                <div className="fixed inset-0 transition-opacity ease-in duration-200">
                    <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                </div>

                <div className="bg-white rounded-lg px-4 pt-5 pb-4 overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full sm:p-6 translate-y-4 sm:translate-y-0 sm:scale-95" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                    <div>
                        <div className="mx-auto flex h-12 full bg-green-100 pt-2">
                            <svg className="h-6 w-6 text-green-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                            </svg>
                            <h3 className="text-lg leading-6 font-medium text-gray-900 pl-2" id="modal-headline">{headingText}</h3>
                        </div>
                        <div className="mt-3 sm:mt-5">
                            <div className="mt-2">
                                {children}
                            </div>
                        </div>
                    </div>
                    <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                        {buttonsList.map((buttonItem) => (
                            <span className={"flex w-full rounded-md shadow-sm sm:col-start-" + buttonItem.seq}>
                                <button type="button" className={buttonItem.buttonClass} onClick={buttonItem.clickFunction}>
                                    {buttonItem.buttonText}
                                </button>
                            </span>
                        ))}
                        {/* <span className="flex w-full rounded-md shadow-sm sm:col-start-2">
                            <button type="button" className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                                Deactivate
                                </button>
                        </span>
                        <span className="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:col-start-1">
                            <button type="button" className="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                                Cancel
                            </button>
                        </span> */}
                    </div>
                </div>
            </div>
        )
    }
}




