import React, { Component } from "react";
import Image from "./Image";

class CardHeader extends Component {
  state = {
    expanded: false
  };
  renderButtons() {
    const { ActionComponent, expanded, onClick } = this.props;
    return (
      <>
        <div className="ml-4 mt-4 flex-shrink-0 cursor-pointer" onClick={onClick}>
          <span className="mr-2 h-7 flex items-center">
            <svg className={(expanded ? "-rotate-180" : "-rotate-0") + " h-4 w-4 transform"} fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
            </svg>
          </span>
        </div >
        {ActionComponent}
      </>
    )
  }
  render() {
    const { settings, onClick } = this.props;
    return (
      <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
        <div className="-ml-4 -mt-4 flex justify-between items-center flex-wrap sm:flex-no-wrap">
          <div className="ml-4 mt-4">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              {settings.headingText}
            </h3>
            <p className="mt-1 text-sm leading-5 text-gray-500">
              {settings.description}
            </p>
          </div>
          {onClick && this.renderButtons(settings.button)}
        </div>
      </div>
    );
  }
}

export default CardHeader;
