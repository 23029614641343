import React, { Component } from "react";

class Pagination extends Component {
  state = {
    hidden: "",
  };
  componentDidMount() {}

  numberOfPages() {
    let lengthOfData = this.props.lengthOfData;
    let number = this.props.numberOfElementsPerPage;
    if (lengthOfData % number === 0) {
      return lengthOfData / number;
    } else {
      return parseInt(lengthOfData / number) + 1;
    }
  }

  showingStringFn() {
    let firstElement = this.props.firstRowIndex + 1;
    let lastElement = this.props.lastRowIndex + 1;
    let number = this.props.numberOfElementsPerPage;

    let pageNumber = lastElement / number;
    if (lastElement % number !== 0) {
      pageNumber = parseInt(lastElement / number) + 1;
    }
    if (this.props.filterOn) {
      let str = "Showing filtered results (Page No. " + pageNumber + ")";
      return str;
    } else if (
      this.props.lengthOfData <
      this.props.numberOfElementsPerPage + 1
    ) {
      // console.log(this.props.lengthOfData);
      return "Showing all results";
    } else {
      return (
        "Showing " +
        firstElement +
        " to " +
        lastElement +
        " of " +
        this.props.lengthOfData +
        " results (Page No. " +
        pageNumber +
        ")"
      );
    }
  }

  render() {
    let lastRowIndex = parseInt(this.props.lastRowIndex);
    let lengthOfData = this.props.lengthOfData;
    let firstRowIndex = this.props.firstRowIndex;
    return (
      <div className="bg-white md:px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
        <div className="w-full grid grid-col-1  sm:hidden">
          <div>
            <p className="text-xs text-gray-700 pt-2">
              {this.showingStringFn()}
            </p>
          </div>
          <div
            className={
              this.props.lengthOfData <= this.props.numberOfElementsPerPage
                ? "hidden"
                : "" + " mt-2 flex-1 flex  justify-between"
            }
          >
            <button
              id="prvButton"
              onClick={this.props.previousPageFn}
              className="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white hover:text-gray-800 text-sm font-medium text-gray-600 hover:bg-gray-50 focus:outline-none"
            >
              Previous
            </button>

            <button
              onClick={this.props.nextPageFn}
              className="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white hover:text-gray-800 text-sm font-medium text-gray-600 hover:bg-gray-50 focus:outline-none"
            >
              Next
            </button>
          </div>
        </div>
        <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
          <div>
            <p className="text-sm text-gray-700">{this.showingStringFn()}</p>
          </div>
          <div
            className={
              this.props.lengthOfData <= this.props.numberOfElementsPerPage
                ? "hidden"
                : ""
            }
          >
            <nav
              className="relative inline-flex shadow-sm -space-x-px"
              aria-label="Pagination"
            >
              <button
                name="previous"
                onClick={this.props.previousPageFn}
                id="previous"
                className="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white hover:text-gray-800 text-sm font-medium text-gray-600 hover:bg-gray-50 focus:outline-none"
              >
                <span className="sr-only">Previous</span>
                {/* Heroicon name: chevron-left */}
                <svg
                  className="h-5 w-5"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
              <button
                onClick={this.props.firstPageFn}
                className={
                  "relative inline-flex items-center px-4 py-2 border border-gray-300 bg-" +
                  (firstRowIndex === 0 ? "gray-200" : "white") +
                  " hover:text-gray-800 text-sm font-medium text-gray-600 hover:bg-gray-50 focus:outline-none "
                }
              >
                1
              </button>

              <span className="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700">
                ...
              </span>
              <button
                name="next"
                id="next"
                onClick={this.props.lastPageFn}
                className={
                  "relative inline-flex items-center px-4 py-2 border border-gray-300 bg-" +
                  (lastRowIndex === lengthOfData - 1 ? "gray-200" : "white") +
                  " hover:text-gray-800 text-sm font-medium text-gray-600 hover:bg-gray-50 focus:outline-none"
                }
              >
                {this.numberOfPages()}
              </button>
              <button
                onClick={this.props.nextPageFn}
                className="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white hover:text-gray-800 text-sm font-medium text-gray-600 hover:bg-gray-50 focus:outline-none"
              >
                <span className="sr-only">Next</span>
                {/* Heroicon name: chevron-right */}
                <svg
                  className="h-5 w-5"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </nav>
          </div>
        </div>
      </div>
    );
  }
}
export default Pagination;
