import Axios from "axios";
import * as constants from "../constants";
export class AgentsRequests {
  //following code converting process date to DD/MM/YYYY format
  pendingProposalRequest(startDate, endDate) {
    let agentId = localStorage.getItem("agentId");

    return Axios.get(
      constants.BASEURL +
        "Agents/PendingProposals/" +
        agentId +
        "/" +
        startDate +
        "/" +
        endDate
    )
      .then((response) => {
        //console.log(response.data);
        return response.data;
      })

      .catch(function (error) {
        console.log(error);
        return "error";
      });
  }
  overDueInvoices(startDate, endDate) {
    let agentId = localStorage.getItem("agentId");

    return Axios.get(
      constants.BASEURL +
        "Agents/studentOverdues/" +
        agentId +
        "/" +
        startDate +
        "/" +
        endDate
    )
      .then((response) => {
        //console.log(response.data);
        return response.data;
      })

      .catch(function (error) {
        console.log(error);
        return "error";
      });
  }

  enrolmentCommission(startDate, endDate) {
    let agentId = localStorage.getItem("agentId");

    return Axios.get(
      constants.BASEURL +
        "Agents/EnrolmentsCommission/" +
        agentId +
        "/" +
        startDate +
        "/" +
        endDate
    )
      .then((response) => {
        //console.log(response.data);
        return response.data;
      })

      .catch(function (error) {
        console.log(error);
        return "error";
      });
  }

  allActiveEnrolments(startDate, endDate) {
    let agentId = localStorage.getItem("agentId");

    return Axios.get(
      constants.BASEURL +
        "Agents/AllEnrolments/" +
        agentId +
        "/1/" +
        startDate +
        "/" +
        endDate
    )
      .then((response) => {
        //console.log(response.data);
        return response.data;
      })

      .catch(function (error) {
        console.log(error);
        return "error";
      });
  }

  agentDocuments(startDate, endDate) {
    let agentId = localStorage.getItem("agentId");

    return Axios.get(
      constants.BASEURL +
        "Agents/AgentDocuments/" +
        agentId +
        "/" +
        startDate +
        "/" +
        endDate
    )
      .then((response) => {
        //console.log(response.data);
        return response.data;
      })

      .catch(function (error) {
        console.log(error);
        return "error";
      });
  }

  paymentRecord(startDate, endDate) {
    let agentId = localStorage.getItem("agentId");

    return Axios.get(
      constants.BASEURL +
        "Agents/PaymentRecord/" +
        agentId +
        "/" +
        startDate +
        "/" +
        endDate
    )
      .then((response) => {
        //console.log(response.data);
        return response.data;
      })

      .catch(function (error) {
        console.log(error);
        return "error";
      });
  }

  communcationsDetails(startDate, endDate) {
    let agentId = localStorage.getItem("agentId");

    return Axios.get(
      constants.BASEURL +
        "Agents/CommunicationsDetails/" +
        agentId +
        "/" +
        startDate +
        "/" +
        endDate
    )
      .then((response) => {
        //console.log(response.data);
        return response.data;
      })

      .catch(function (error) {
        console.log(error);
        return "error";
      });
  }
  agentCommissionDue(startDate, endDate) {
    let agentId = localStorage.getItem("agentId");

    return Axios.get(
      constants.BASEURL +
        "Agents/AgentCommissionDue/" +
        agentId +
        "/" +
        startDate +
        "/" +
        endDate
    )
      .then((response) => {
        //console.log(response.data);
        return response.data;
      })

      .catch(function (error) {
        console.log(error);
        return "error";
      });
  }

  feesPaidEnrolments(startDate, endDate) {
    let agentId = localStorage.getItem("agentId");

    return Axios.get(
      constants.BASEURL +
        "Agents/FeesPaidEnrolments/" +
        agentId +
        "/" +
        startDate +
        "/" +
        endDate
    )
      .then((response) => {
        //console.log(response.data);
        return response.data;
      })

      .catch(function (error) {
        console.log(error);
        return "error";
      });
  }
  sendSms(number, message) {
    let agentName = localStorage.getItem("agentName");
    let senderID = agentName.replace(/\W/gi, "").toUpperCase().substring(0, 9);

    return Axios.get(
      constants.BASEURL + "DirectSMS/" + number + "/" + message + "/" + senderID
    )
      .then((response) => {
        //console.log(response.data);
        return response.data;
      })

      .catch(function (error) {
        console.log(error);
        return "error";
      });
  }
}

export default AgentsRequests;
