import React, { Component } from "react";

class Icon extends Component {
  state = {};
  render() {
    return (
      <svg
        className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
        fill="currentColor"
        viewBox="0 0 20 20"
      >
        <path
          fillRule="evenodd"
          d={this.props.src}
          clipRule="evenodd"
        />
      </svg>
    );
  }
}

export default Icon;
