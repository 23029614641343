import Axios from "axios";
import Loader from "react-loader";
import React, { Component } from "react";
import DateBox from "./controls/DateBox";
import * as constants from "../constants";
import moment from "moment";
import Transition from "./common/Transition";
import { DateFormatter } from "./common/DateFormatter";
import TableComponent from "./common/TableComponent";
import MultiSelectBox from "./controls/MultiSelectBox";
import "../assets/css/additional.css";
import { AgentsRequests } from "../apiRequests/AgentsRequests";
import ToolTip from "./common/ToolTip";
import { currencyFormat, multiselectFilter } from "./common/commonFns";
import SearchBox from "./controls/SearchBox";
import Uploader from "./controls/Uploader";

const dateFormatter = new DateFormatter();
const agentsRequests = new AgentsRequests();
const svgs = {
  attachment: (
    <svg
      className="w-5 h-5"
      fill="currentColor"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM6.293 6.707a1 1 0 010-1.414l3-3a1 1 0 011.414 0l3 3a1 1 0 01-1.414 1.414L11 5.414V13a1 1 0 11-2 0V5.414L7.707 6.707a1 1 0 01-1.414 0z"
        clipRule="evenodd"
      ></path>
    </svg>
  ),
};

class PendingProposals extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uploadFile: null,
      isOpen: false,
      tableEmpty: "hidden",
      background: "hidden",
      uploaderBkgrd: "hidden",
      dataLength: 0,
      tableRows: [],
      isLoaded: true,
      filterOn: false,
      hidden: "hidden",
      expanded: "",
      allPrograms: [],
      allCampuses: [],
      allIntakes: [],
      fromDate: "",
      toDate: "",
      allAccountManagers: [],
      programsSelectedList: [],
      campusSelectedList: [],
      intakesSelectedList: [],
      acManagerSelectedList: [],
      pProposalData: [],
      PendingProposaloData: [],
      uploaderHidden: "hidden",
      agentId: localStorage.getItem("agentId"),
      sortBy: "",
      fileObjectID: null,
      firstRowIndex: 0,
      lastRowIndex: constants.NUMBEROFROWS - 1,
      upAlphabetSvg: "gray",
      searchFieldValue: "",
      alphabeticOrder: "alphabetic",
      commissionOrder: "commissionLow",
      downAlphabetSvg: "gray",
      upCommissionnSvg: "gray",
      downCommissionnSvg: "gray",
      tooltipHidden: true,
      fileProposalID: null,
    };
    this.handleDateBox = this.handleDateBox.bind(this);
    this.setPageIndexFn = this.setPageIndexFn.bind(this);
  }

  componentDidMount() {
    let startDate = localStorage.getItem("t1StartDate");
    let endDate = localStorage.getItem("t1EndDate");

    // following line of code will turn on the loader
    this.setState({
      hidden: "",
      isLoaded: false,
      fromDate: startDate,
      toDate: endDate,
    });
    this.pendingProposalData(startDate, endDate);
  }

  // following function will make a new request for data
  pendingProposalData(startDate, endDate) {
    agentsRequests.pendingProposalRequest(startDate, endDate).then((result) => {
      this.loadMultiSelectData(result);
    });
  }

  // following function will generate data for all Multiselect fields
  loadMultiSelectData(pProposalData) {
    let allPrograms = [];
    let allCampuses = [];
    let allIntakes = [];
    let allAccountManagers = [];
    let data = pProposalData;

    data.map((object) => {
      if (allPrograms.some((item) => object.courseName === item)) {
      } else {
        allPrograms.push(object.courseName);
      }
    });

    data.map((object) => {
      if (allCampuses.some((item) => object.campus === item)) {
      } else {
        allCampuses.push(object.campus);
      }
    });

    data.map((object) => {
      if (
        allIntakes.some((item) => object.intake === item) ||
        object.intake === ""
      ) {
      } else {
        allIntakes.push(object.intake);
      }
    });

    data.map((object) => {
      if (
        allAccountManagers.some((item) => object.accountManagerName === item)
      ) {
      } else {
        allAccountManagers.push(object.accountManagerName);
      }
    });
    this.setState(
      {
        allPrograms: allPrograms,
        allCampuses: allCampuses,
        allIntakes: allIntakes,
        allAccountManagers: allAccountManagers,
        pProposalData: pProposalData,
      },
      function () {
        this.loadData();
      }
    );
  }

  // following function will firstly make function calls to apply filters if any and
  // then will process the data to display in the table and then store that data in
  // tableRows Array
  loadData() {
    let rowItem = {};
    let tableRows = [];
    let buttonIndex = 0;
    let data = this.state.pProposalData;

    let firstRowIndex = this.state.firstRowIndex;
    let lastRowIndex = this.state.lastRowIndex;

    // following call will filter data
    data = this.dataProcessor(data);

    data = this.dataSortingFn(data);

    let dataLength = data.length;

    if (dataLength < parseInt(firstRowIndex) + 1) {
      firstRowIndex = 0;
      lastRowIndex = constants.NUMBEROFROWS - 1;
    }

    if (data != "" && data != null) {
      data.map((object, index) => {
        if (index >= firstRowIndex && index <= lastRowIndex) {
          rowItem.proposalID = object.proposalID;
          rowItem.ImgSrc =
            "https://ui-avatars.com/api/name=" +
            object.studentName +
            "/?background=random";
          rowItem.Label1 = object.studentName;
          rowItem.PillTxt = object.proposalNo;
          rowItem.PillClr = "green";
          rowItem.DescriptionClm1 =
            "Processed On: " + moment(object.proposalDate).format("DD-MM-YYYY");

          rowItem.Label2 = object.courseName;
          let sDate = moment(object.courseStartDate, "DD/MM/YYYY");
          let eDate = moment(object.courseEndDate, "DD/MM/YYYY");
          rowItem.DescriptionClm2 =
            moment(sDate).format("DD-MM-YYYY") +
            " to " +
            moment(eDate).format("DD-MM-YYYY");

          rowItem.Campus = object.campus;

          rowItem.Value = "$" + currencyFormat(object.commission);

          if (object.accountManagerName) {
            rowItem["Account Manager"] = object.accountManagerName;
          } else {
            rowItem["Account Manager"] = "N/A";
          }

          if (object.intake) {
            rowItem.Label2 = rowItem.Label2 + " (" + object.intake + ")";
          } else {
            rowItem.Label2 = rowItem.Label2 + "";
          }
          rowItem[" "] = (
            <div className="relative flex justify-end">
              <button
                onClick={() => {
                  this.eventHandeler(index, "attachment");
                }}
                className="mx-2 my-1 w-5 h-5 inline-flex items-center justify-center text-gray-400 rounded-full hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
              >
                <div className="tooltip">
                  {svgs.attachment}
                  <ToolTip text={"Click to upload a File"} />
                </div>
              </button>
            </div>
          );

          tableRows.push({ ...rowItem });
          buttonIndex = buttonIndex + 1;
        }
      });
      if (dataLength < this.state.pProposalData.length) {
        this.setState({ filterOn: true });
      } else {
        this.setState({ filterOn: false });
      }
    } else {
      //following line will unhide Nodata View
      this.setState({ tableEmpty: "" });
    }

    this.setState({ tableRows: tableRows });
    // following line of code will turn off the loader
    this.setState({ hidden: "hidden", isLoaded: true });
  }

  setPageIndexFn(firstRowIndex, lastRowIndex) {
    // console.log(firstRowIndex);
    // console.log(lastRowIndex);

    this.setState(
      { firstRowIndex: firstRowIndex, lastRowIndex: lastRowIndex },
      function () {
        this.loadData();
      }
    );
  }
  // following function will render the Table component
  renderTable() {
    let appDetailsBtn = this.tableHeadingButton(
      "Student Details",
      this.state.alphabeticOrder,
      this.state.upAlphabetSvg,
      this.state.downAlphabetSvg
    );
    let commissionBtn = this.tableHeadingButton(
      "Commission",
      this.state.commissionOrder,
      this.state.upCommissionnSvg,
      this.state.downCommissionnSvg
    );
    let tableHeadings = [
      appDetailsBtn,
      "Program Details",
      "Campus",
      "Account Manager",
      commissionBtn,
      " ",
    ];
    let tableRows = [...this.state.tableRows];
    //console.log(tableRows.length);
    if (tableRows.length >= 1) {
      return (
        <TableComponent
          tableRows={tableRows}
          tableHeadings={tableHeadings}
          filterOn={this.state.filterOn}
          dataLength={this.state.dataLength}
          setPageIndexFn={this.setPageIndexFn}
          firstRowIndex={this.state.firstRowIndex}
          lastRowIndex={this.state.lastRowIndex}
          clmTwoWidth={"w-1/4"}
          numberOfElementsPerPage={constants.NUMBEROFROWS}
        />
      );
    } else {
      return (
        <div
          className={
            this.state.tableEmpty +
            " text-gray-600 w-full flex justify-center mt-20"
          }
        >
          <h1>
            There is No Data Available to Display, please reset filters if any
            applied.
          </h1>
        </div>
      );
    }
  }

  dataSortingFn(data) {
    let sortBy = this.state.sortBy;
    if (sortBy === "alphabetic") {
      data.sort((a, b) => a.studentName.localeCompare(b.studentName));
    } else if (this.state.sortBy === "reverseAlphabetic") {
      data.sort((a, b) => b.studentName.localeCompare(a.studentName));
    } else if (this.state.sortBy === "commissionLow") {
      data.sort(function (a, b) {
        return b.commission - a.commission;
      });
    } else if (this.state.sortBy === "commissionHigh") {
      data.sort(function (a, b) {
        return a.commission - b.commission;
      });
    }

    return data;
  }

  dataProcessor(data) {
    let originalData = data;

    let programsSelectedList = this.state.programsSelectedList;
    let campusSelectedList = this.state.campusSelectedList;
    let intakesSelectedList = this.state.intakesSelectedList;
    let acManagerSelectedList = this.state.acManagerSelectedList;

    if (this.state.searchFieldValue != "") {
      let searchValue = this.state.searchFieldValue;
      document.getElementById("search").value = searchValue;
      data = data.filter((object) =>
        object.studentName.toUpperCase().includes(searchValue.toUpperCase())
      );
      this.setState({ filterOn: true });
      if (data.length === originalData.length) {
        this.setState({ filterOn: false });
      }
    }

    if (programsSelectedList != "") {
      data = multiselectFilter(data, programsSelectedList, "courseName");
    }
    if (campusSelectedList != "") {
      data = multiselectFilter(data, campusSelectedList, "campus");
    }
    if (intakesSelectedList != "") {
      data = multiselectFilter(data, intakesSelectedList, "intake");
    }

    if (acManagerSelectedList != "") {
      data = multiselectFilter(
        data,
        acManagerSelectedList,
        "accountManagerName"
      );
    }
    // following line of code will set dataLength
    this.setState({ dataLength: data.length });
    return data;
  }

  tableHeadingButton(name, order, upSvgClr, downSvgClr) {
    return (
      <div>
        <div className="hidden lg:block">
          {name}
          <button
            onClick={() => {
              this.sortingEventHandeler(order);
            }}
            className="ml-2 w-7 h-4 inline-flex items-center justify-center border-2 border-transparent hover:border-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
          >
            <svg
              className="w-3 h-4 -rotate-180 transform "
              stroke={upSvgClr}
              strokeWidth="2"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M16.707 10.293a1 1 0 010 1.414l-6 6a1 1 0 01-1.414 0l-6-6a1 1 0 111.414-1.414L9 14.586V3a1 1 0 012 0v11.586l4.293-4.293a1 1 0 011.414 0z"
                clipRule="evenodd"
              ></path>
            </svg>
            <svg
              className="w-3 h-4"
              stroke={downSvgClr}
              strokeWidth="2"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M16.707 10.293a1 1 0 010 1.414l-6 6a1 1 0 01-1.414 0l-6-6a1 1 0 111.414-1.414L9 14.586V3a1 1 0 012 0v11.586l4.293-4.293a1 1 0 011.414 0z"
                clipRule="evenodd"
              ></path>
            </svg>
          </button>
        </div>
        <div className="lg:hidden">{name}</div>
      </div>
    );
  }

  sortingEventHandeler(type) {
    if (type === "alphabetic") {
      this.setState(
        {
          sortBy: "alphabetic",
          alphabeticOrder: "reverseAlphabetic",
          upAlphabetSvg: "blue",
          downAlphabetSvg: "gray",
          upCommissionnSvg: "gray",
          downCommissionnSvg: "gray",
        },
        function () {
          this.loadData();
        }
      );
    } else if (type === "reverseAlphabetic") {
      this.setState(
        {
          sortBy: "reverseAlphabetic",
          alphabeticOrder: "alphabetic",
          upAlphabetSvg: "gray",
          downAlphabetSvg: "blue",
          upCommissionnSvg: "gray",
          downCommissionnSvg: "gray",
        },
        function () {
          this.loadData();
        }
      );
    } else if (type === "commissionHigh") {
      this.setState(
        {
          sortBy: "commissionHigh",
          commissionOrder: "commissionLow",
          upAlphabetSvg: "gray",
          downAlphabetSvg: "gray",
          upCommissionnSvg: "blue",
          downCommissionnSvg: "gray",
        },
        function () {
          this.loadData();
        }
      );
    } else if (type === "commissionLow") {
      this.setState(
        {
          sortBy: "commissionLow",
          commissionOrder: "commissionHigh",
          upAlphabetSvg: "gray",
          downAlphabetSvg: "gray",
          upCommissionnSvg: "gray",
          downCommissionnSvg: "blue",
        },
        function () {
          this.loadData();
        }
      );
    }
  }

  eventHandeler(index, event) {
    // let tableRows = this.state.tableRows;

    if (event === "attachment") {
      const fileProposalID = this.state.tableRows[index].proposalID;
      // console.log("Attachment clicked PID: " + fileProposalID);
      this.setState({
        uploaderBkgrd: "",
        uploaderHidden: "",
        fileProposalID: fileProposalID,
      });
    } else if (event === "close") {
      this.setState({ uploaderBkgrd: "hidden", uploaderHidden: "hidden" });
    }
  }

  searchHandeler() {
    var searchValue = document.getElementById("search").value;
    this.setState(
      {
        searchFieldValue: searchValue,
      },
      function () {
        this.loadData();
      }
    );
  }

  onMultiSelectChange(selectedList, item, id) {
    if (id === "program") {
      this.setState({
        programsSelectedList: selectedList,
      });
    } else if (id === "campus") {
      this.setState({
        campusSelectedList: selectedList,
      });
    } else if (id === "intake") {
      this.setState({
        intakesSelectedList: selectedList,
      });
    } else if (id === "acManager") {
      this.setState({
        acManagerSelectedList: selectedList,
      });
    }
    this.setState(
      {
        isOpen: false,
        expanded: "",
        background: "hidden",
      },
      function () {
        this.loadData();
      }
    );
  }

  filtersDropdown() {
    let expanded = this.state.expanded;
    if (expanded) {
      this.setState({
        isOpen: false,
        expanded: "",
        background: "hidden",
      });
    } else {
      this.setState({
        isOpen: true,
        expanded: "yes",
        background: "",
      });
    }
  }

  resetDatesFn() {
    this.setState(
      {
        fromDate: "",
        toDate: "",
        // isOpen: false,
        // expanded: "",
        // background: "hidden",
        // filterPanelHeight: "0",
      },
      function () {
        //console.log(this.state.fromDate);
        this.loadData();
      }
    );
  }

  handleDateBox(selectedDate, name) {
    let fromDate = new Date(dateFormatter.formatterThree(this.state.fromDate));
    let toDate = new Date(dateFormatter.formatterThree(this.state.toDate));
    let sDate = new Date(dateFormatter.formatterThree(selectedDate));
    localStorage.setItem("dateRangeLabel1", "Custom Range");
    if (name === "FromDate") {
      let startDate = dateFormatter.formatterTwo(sDate);
      let endDate = dateFormatter.formatterTwo(toDate);
      localStorage.setItem("t1StartDate", startDate);
      this.setState(
        {
          fromDate: selectedDate,
          isOpen: false,
          expanded: "",
          background: "hidden",
        },
        function () {
          this.pendingProposalData(startDate, endDate);
        }
      );
    } else if (name === "ToDate") {
      let startDate = dateFormatter.formatterTwo(fromDate);
      let endDate = dateFormatter.formatterTwo(sDate);
      localStorage.setItem("t1EndDate", endDate);
      this.setState(
        {
          toDate: selectedDate,
          isOpen: false,
          expanded: "",
          background: "hidden",
        },
        function () {
          this.pendingProposalData(startDate, endDate);
        }
      );
    }
  }

  renderAdvancedSearch() {
    return (
      <div>
        <div className="md:mt-5 mt-1 flex xl:justify-end">
          <span
            onClick={() => this.filtersDropdown()}
            className="  p-2 h-7 rounded-md bg-white border border-gray-300 text-gray-700 hover:bg-gray-200 cursor-pointer"
          >
            Advanced Search
          </span>
        </div>
        <Transition
          show={this.state.isOpen}
          enter="transition ease-in duration-300 transform"
          enterFrom="opacity-25 scale-0"
          enterTo="opacity-100 scale-100"
          leave="transition ease-out duration-500 transform"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-25 scale-0"
        >
          <div className="origin-top right-0 absolute z-10 mb-8 w-full bg-gray-200">
            <div className="px-2 pt-4 lg:mt-0 flex-shrink-0 bg-gray-100 sm:rounded-lg border-b border-gray-200">
              <div className={"mx-4 mb-4 bg-gray-100"}>
                <div className="pb-4">
                  <div
                    className={" text-gray-600 w-full flex justify-center mb-4"}
                  >
                    <h1 className="font-semibold">Filters</h1>
                  </div>
                  <div className=" grid grid-cols-6 gap-6">
                    <div className="md:py-3 col-span-6 md:col-span-3 ">
                      <MultiSelectBox
                        id="program"
                        label="Select Programs"
                        selectedValues={this.state.programsSelectedList}
                        data={this.state.allPrograms}
                        onSelect={(selectedList, selectedItem) =>
                          this.onMultiSelectChange(
                            selectedList,
                            selectedItem,
                            "program"
                          )
                        }
                        onRemove={(selectedList, removedItem) =>
                          this.onMultiSelectChange(
                            selectedList,
                            removedItem,
                            "program"
                          )
                        }
                      />
                    </div>
                    <div className="md:py-3 col-span-6 md:col-span-3 ">
                      <MultiSelectBox
                        id="campus"
                        label="Select Campuses"
                        selectedValues={this.state.campusSelectedList}
                        onSelect={(selectedList, selectedItem) =>
                          this.onMultiSelectChange(
                            selectedList,
                            selectedItem,
                            "campus"
                          )
                        }
                        onRemove={(selectedList, removedItem) =>
                          this.onMultiSelectChange(
                            selectedList,
                            removedItem,
                            "campus"
                          )
                        }
                        data={this.state.allCampuses}
                        placeholder="Select"
                      />
                    </div>
                    <div className="md:py-3 col-span-6 md:col-span-3 ">
                      <MultiSelectBox
                        id="acManager"
                        label="Select Account Managers"
                        selectedValues={this.state.acManagerSelectedList}
                        onSelect={(selectedList, selectedItem) =>
                          this.onMultiSelectChange(
                            selectedList,
                            selectedItem,
                            "acManager"
                          )
                        }
                        onRemove={(selectedList, removedItem) =>
                          this.onMultiSelectChange(
                            selectedList,
                            removedItem,
                            "acManager"
                          )
                        }
                        data={this.state.allAccountManagers}
                        placeholder="Select"
                      />
                    </div>
                    <div
                      className={
                        (this.state.allIntakes != "" ? "" : "hidden") +
                        " md:py-3 col-span-6 md:col-span-3 "
                      }
                    >
                      <MultiSelectBox
                        id="intake"
                        label="Select Intakes"
                        selectedValues={this.state.intakesSelectedList}
                        onSelect={(selectedList, selectedItem) =>
                          this.onMultiSelectChange(
                            selectedList,
                            selectedItem,
                            "intake"
                          )
                        }
                        onRemove={(selectedList, removedItem) =>
                          this.onMultiSelectChange(
                            selectedList,
                            removedItem,
                            "intake"
                          )
                        }
                        data={this.state.allIntakes}
                        placeholder="Select"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Transition>
        <div className="">
          <span
            onClick={() => this.filtersDropdown()}
            className={
              this.state.background +
              " z-6 fixed top-10 left-0 right-0 bottom-0 h-full w-full bg-gray-400 opacity-75"
            }
          />
        </div>
      </div>
    );
  }

  upload(file, docTypeId) {
    this.setState({ uploadFile: file });
  }

  async uploadFile() {
    var file = this.state.uploadFile;
    const objectType = 29; //proposal type
    const objectID = parseInt(this.state.fileProposalID); //Applicant Id
    if (file) {
      if (file.length > 0) {
        this.setState({
          hidden: "",
          isLoaded: false,
        });

        let form = new FormData();

        form.append("file", file[0]);
        form.append("objectId", objectID);
        form.append("objectType", objectType);
        form.append("isPublic", 1);

        try {
          Axios.post(constants.BASEURL + "Document", form).then((result) => {
            if ((result.data.sucess = false)) {
              alert("Fail");
            } else {
              alert("Success");
              this.setState({
                uploaderHidden: "hidden",
                uploaderBkgrd: "hidden",
                hidden: "hidden",
                isLoaded: true,
              });
            }
          });
        } catch (e) {
          alert("Error while uploading ");
        }
      }
    }
  }

  // following function will rende main GUI
  render() {
    const { isLoaded } = this.state;
    return (
      <div className="relative mt-6 align-middle min-h-full">
        <span
          className={
            this.state.hidden +
            " z-20 fixed top-10 left-0 right-0 bottom-0 h-full w-full bg-white opacity-50"
          }
        />
        <Loader loaded={isLoaded}></Loader>
        <div className="flex rounded-md p-4 mb-4 md:mb-8 w-full grid grid-cols-1 gap-5 md:grid-cols-2 xl:grid-cols-6 bg-gray-100">
          <div className="xl:col-span-2">
            <SearchBox
              id={"search"}
              label={"Search For Student Name"}
              svgType={"student"}
              placeholder={"Name"}
              value={this.state.searchFieldValue}
              searchHandler={() => {
                this.searchHandeler();
              }}
            />
          </div>

          <div className="xl:col-span-1">
            <DateBox
              id={"Pd1"}
              label="Processed From Date"
              //minDate={moment().add(0, "days").toDate()}
              onChange={this.handleDateBox}
              name={"FromDate"}
              value={this.state.fromDate ? new Date(this.state.fromDate) : ""}
            />
          </div>
          <div className="xl:col-span-1">
            <DateBox
              id={"Pd1"}
              label="Processed Till Date"
              //minDate={moment().add(0, "days").toDate()}
              onChange={this.handleDateBox}
              name={"ToDate"}
              value={this.state.toDate ? new Date(this.state.toDate) : ""}
              //onChange={this.handleChange}
            />
          </div>
          <div className="xl:col-span-2">{this.renderAdvancedSearch()}</div>
        </div>
        <div
          className={
            this.state.uploaderHidden +
            " z-40 child w-70per h-60per bg-yellow-100 p-6"
          }
        >
          <div className="h-80percent">
            <Uploader
              onUpload={(file, docTypeId) => {
                this.upload(file, docTypeId);
              }}
              docTypeId={"AgentDocuments"}
            />
          </div>
          <div className="sm:border-t sm:border-gray-200 sm:pt-5 w-full flex justify-center">
            <button
              id="submit"
              type="button"
              onClick={() => {
                this.uploadFile();
              }}
              className="inline-flex justify-center py-1 px-4 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
            >
              Submit
            </button>
          </div>
        </div>
        <div className="mb-6 w-full align-middle">{this.renderTable()}</div>
        <div className="">
          <span
            onClick={() => this.eventHandeler(0, "close")}
            className={
              this.state.uploaderBkgrd +
              " z-30 fixed top-10 left-0 right-0 bottom-0 h-full w-full bg-black opacityBackground"
            }
          />
        </div>
      </div>
    );
  }
}

export default PendingProposals;
