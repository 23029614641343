import React, { Component } from "react";
import moment from "moment";
import DataTile from "./DataTile";
import DateBox from "./controls/DateBox";
import SearchBox from "./controls/SearchBox";
import Loader from "react-loader";
import { AgentsRequests } from "../apiRequests/AgentsRequests";
import { DateFormatter } from "./common/DateFormatter";
import MultiSelectBox from "./controls/MultiSelectBox";
import { multiselectFilter } from "./common/commonFns";
import Transition from "./common/Transition";

const dateFormatter = new DateFormatter();
const agentsRequests = new AgentsRequests();

const eyeSVG = (
  <svg
    className="w-5 h-4"
    fill="none"
    stroke="currentColor"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
    />
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
    />
  </svg>
);

class CommunicationsView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataEmpty: "hidden",
      hidden: "hidden",
      isLoaded: true,
      communicationsData: [],
      isOpen: false,
      filteredCommunicationsData: [],
      fromDate: localStorage.getItem("t3StartDate"),
      toDate: localStorage.getItem("t3EndDate"),
      isOpen: false,
      expanded: "",
      isEmailOpen: false,
      emailExpanded: "",
      senderSearchvalue: "",
      subjectSearchvalue: "",
      background: "hidden",
      emailbackground: "hidden",
      emailData: <div></div>,
      typeSelectedList: [],
      typeMsData: ["Email", "Contact Log", "SMS"],
    };
    this.handleDateBox = this.handleDateBox.bind(this);
  }

  componentDidMount() {
    this.setState({
      hidden: "",
      isLoaded: false,
    });
    let startDate = dateFormatter.formatterTwo(
      localStorage.getItem("t3StartDate")
    );
    let endDate = dateFormatter.formatterTwo(localStorage.getItem("t3EndDate"));
    this.communicationsDetailsFn(startDate, endDate);
  }

  communicationsDetailsFn(startDate, endDate) {
    agentsRequests.communcationsDetails(startDate, endDate).then((result) => {
      let responseData = result;
      if (responseData !== "error") {
        this.setState(
          {
            communicationsData: responseData,
            filteredCommunicationsData: responseData,
          },
          function () {
            this.setState({
              hidden: "hidden",
              isLoaded: true,
            });
          }
        );
      } else {
        this.setState({ dataEmpty: "" });
      }
    });
  }

  searchHandeler(id) {
    let data = this.state.communicationsData;
    if (id === "sender") {
      var searchValue = document.getElementById("sender").value;
      this.setState({ senderSearchvalue: searchValue, subjectSearchvalue: "" });

      if (searchValue != "") {
        data = data.filter((object) =>
          object.from
            .replace("From: ", "")
            .toUpperCase()
            .includes(searchValue.toUpperCase())
        );
      }
    } else if (id === "subject") {
      var searchValue = document.getElementById("subject").value;
      this.setState({ senderSearchvalue: "", subjectSearchvalue: searchValue });

      if (searchValue != "") {
        data = data.filter((object) =>
          object.subject
            .replace("Subject: ", "")
            .toUpperCase()
            .includes(searchValue.toUpperCase())
        );
      }
    }

    if (data.length === 0) {
      this.setState({ dataEmpty: "" });
    } else {
      this.setState({ dataEmpty: "hidden" });
    }

    this.setState({ filteredCommunicationsData: data });
  }

  onMultiSelectChange(selectedList, item, id) {
    if (id === "type") {
      this.setState(
        {
          typeSelectedList: selectedList,
        },
        function () {
          this.renderTiles();
        }
      );
    }
  }

  showDataView(from, subject, emailHtml, type) {
    let emailData = (
      <div className="bg-gray-200">
        <p className="pt-2 text-green-700 text-md md:text-lg mb-4 text-center font-semibold">
          {type}
        </p>
        <div className="text-sm md:text-sm">
          <div className="pb-1 border-dotted border-b-2 border-gray-400">
            <p className="p-2 text-gray-900 ">{from}</p>
            <p className="p-2 mt-1  text-gray-900 ">{subject}</p>
          </div>
          <div
            className="p-4 mt-3 text-gray-700"
            dangerouslySetInnerHTML={{ __html: emailHtml }}
          ></div>
        </div>
      </div>
    );
    this.setState({
      isEmailOpen: true,
      emailbackground: "",
      emailData: emailData,
    });
    // return()
  }

  closeDataView() {
    this.setState({
      isEmailOpen: false,
      emailbackground: "hidden",
      emailData: <div></div>,
    });
    // return()
  }

  handleDateBox(selectedDate, name) {
    let fromDate = new Date(dateFormatter.formatterThree(this.state.fromDate));
    let toDate = new Date(dateFormatter.formatterThree(this.state.toDate));
    let sDate = new Date(dateFormatter.formatterThree(selectedDate));

    if (name === "FromDate") {
      let startDate = dateFormatter.formatterTwo(sDate);
      let endDate = dateFormatter.formatterTwo(toDate);
      localStorage.setItem("t3StartDate", startDate);
      localStorage.setItem("dateRangeLabel3", "Custom Range");
      this.setState(
        {
          fromDate: selectedDate,
          isOpen: false,
          expanded: "",
          background: "hidden",
        },
        function () {
          //console.log(this.state.fromDate);
          this.communicationsDetailsFn(startDate, endDate);
        }
      );
    } else if (name === "ToDate") {
      let startDate = dateFormatter.formatterTwo(fromDate);
      let endDate = dateFormatter.formatterTwo(sDate);
      localStorage.setItem("t3EndDate", endDate);
      localStorage.setItem("dateRangeLabel3", "Custom Range");
      this.setState(
        {
          toDate: selectedDate,
          isOpen: false,
          expanded: "",
          background: "hidden",
        },
        function () {
          this.communicationsDetailsFn(startDate, endDate);
        }
      );
    }
  }

  dataProcessor(data) {
    let typeSelectedList = this.state.typeSelectedList;
    if (typeSelectedList != "") {
      data = multiselectFilter(data, typeSelectedList, "title");
    }

    return data;
  }

  displayDate(changeD, date) {
    if (changeD) {
      let cdate = moment(changeD, "DD/MM/YYYY");
      return "Date: " + moment(cdate).format("DD-MM-YYYY");
    } else {
      return "Date: " + moment(date).format("DD-MM-YYYY");
    }
  }

  renderTiles() {
    let communicationsData = this.state.filteredCommunicationsData;
    communicationsData = this.dataProcessor(communicationsData);
    if (communicationsData.length >= 1) {
      return (
        <div className="bg-gray-100 h-70vh  shadow rounded-lg overflow-auto">
          <div className="p-4 mt-5 grid grid-cols-1 gap-5 md:grid-cols-1 xl:grid-cols-2 2xl:grid-cols-4 ">
            {communicationsData.map((object, index) => (
              <div key={"id" + index}>
                <DataTile
                  id={"id" + index}
                  svgType={object.title}
                  title={object.title}
                  description1={
                    <div className="text-xs text-gray-500">
                      <p>{this.displayDate(object.changeDate, object.date)}</p>
                      <p
                        className={
                          object.title === "Contact Log" ? "" : "hidden"
                        }
                      >
                        {"Type: " + object.logType}
                      </p>
                    </div>
                  }
                  description2={
                    <div className="text-xs">
                      <div className="">
                        <p> {object.from}</p>
                      </div>
                      <div className="">
                        <div
                          onClick={() =>
                            this.showDataView(
                              object.from,
                              "Date: " +
                                this.displayDate(
                                  object.changeDate,
                                  object.date
                                ),
                              object.message,
                              object.title
                            )
                          }
                          className={object.title === "Email" ? "hidden" : ""}
                        >
                          <div className="grid grid-cols-7">
                            <div className="col-span-6 text-xs text-gray-500 truncate">
                              <p
                                className={
                                  object.title === "Email"
                                    ? "hidden"
                                    : "" + " truncate"
                                }
                              >
                                {object.message === ""
                                  ? "Empty"
                                  : object.message}
                              </p>
                            </div>
                            <div className="col-span-1 flex justify-end">
                              <div className="hover:text-blue-700 text-sm text-gray-600 hover:font-semibold cursor-pointer">
                                {eyeSVG}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          onClick={() =>
                            this.showDataView(
                              object.from,
                              object.subject,
                              object.message,
                              object.title
                            )
                          }
                          className={object.title === "Email" ? "" : "hidden"}
                        >
                          <div className="grid grid-cols-7">
                            <div className="col-span-6 text-xs text-gray-500 truncate">
                              <p>{object.subject}</p>
                            </div>
                            <div className="col-span-1 flex justify-end">
                              <div className="hover:text-blue-700 text-sm text-gray-600 hover:font-semibold cursor-pointer">
                                {eyeSVG}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                />
              </div>
            ))}
          </div>
        </div>
      );
    } else {
      return (
        <div
          className={
            this.state.dataEmpty +
            " text-gray-600 w-full flex justify-center mt-20"
          }
        >
          <h1>
            There is No Data Available to Display, please reset filters if any
            applied.
          </h1>
        </div>
      );
    }
  }

  render() {
    return (
      <div className="relative mt-6 align-middle">
        <span
          className={
            this.state.hidden +
            " z-30 fixed top-10 left-0 right-0 bottom-0 h-full w-full bg-white opacity-50"
          }
        />
        <Loader loaded={this.state.isLoaded}></Loader>

        <div className="flex rounded-md p-4 mb-4 md:mb-8 w-full grid grid-cols-1 gap-5 md:grid-cols-2 xl:grid-cols-6 bg-gray-100">
          <div className="xl:col-span-2">
            <SearchBox
              id={"sender"}
              label={"Search For Sender's Name"}
              svgType={"student"}
              placeholder={"Name"}
              value={this.state.senderSearchvalue}
              searchHandler={() => {
                this.searchHandeler("sender");
              }}
            />
          </div>
          <div className="xl:col-span-2">
            <SearchBox
              id={"subject"}
              label={"Search For Email Subject"}
              svgType={"textFile"}
              placeholder={"Subject"}
              value={this.state.subjectSearchvalue}
              searchHandler={() => {
                this.searchHandeler("subject");
              }}
            />
          </div>
          <div className="xl:col-span-2 ">
            <MultiSelectBox
              id="type"
              label="Select Communication Type"
              selectedValues={this.state.typeSelectedList}
              data={this.state.typeMsData}
              onSelect={(selectedList, selectedItem) =>
                this.onMultiSelectChange(selectedList, selectedItem, "type")
              }
              onRemove={(selectedList, removedItem) =>
                this.onMultiSelectChange(selectedList, removedItem, "type")
              }
            />
          </div>
          <div className="xl:col-span-1">
            <DateBox
              id={"Ud1"}
              label="Date From"
              //minDate={moment().add(0, "days").toDate()}
              onChange={this.handleDateBox}
              name={"FromDate"}
              value={this.state.fromDate ? new Date(this.state.fromDate) : ""}
            />
          </div>
          <div className="xl:col-span-1">
            <DateBox
              id={"Ud1"}
              label="Date Till"
              //minDate={moment().add(0, "days").toDate()}
              onChange={this.handleDateBox}
              name={"ToDate"}
              value={this.state.toDate ? new Date(this.state.toDate) : ""}
              //onChange={this.handleChange}
            />
          </div>
        </div>
        <Transition
          show={this.state.isEmailOpen}
          enter="transition ease-in duration-300 transform"
          enterFrom="opacity-25 scale-0"
          enterTo="opacity-100 scale-100"
          leave="transition ease-out duration-500 transform"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-25 scale-0"
        >
          <div className="z-40 origin-top Data-View">
            <div className="p-2 lg:mt-0 flex-shrink-0 bg-gray-100 sm:rounded-lg border-b border-gray-200">
              <div className={" bg-gray-100"}>
                <div>{this.state.emailData}</div>
              </div>
            </div>
          </div>
        </Transition>
        <div className="">
          <span
            onClick={() => this.closeDataView()}
            className={
              this.state.emailbackground +
              " z-30 fixed top-10 left-0 right-0 bottom-0 h-full w-full bg-black opacityBackground"
            }
          />
        </div>

        {this.renderTiles()}
      </div>
    );
  }
}

export default CommunicationsView;
