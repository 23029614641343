import React, { Component } from "react";
import moment from "moment";
import Loader from "react-loader";
import DataTile from "./DataTile";
import DateBox from "./controls/DateBox";
import SearchBox from "./controls/SearchBox";
import Axios from "axios";
import { AgentsRequests } from "../apiRequests/AgentsRequests";
import { DateFormatter } from "./common/DateFormatter";
import Uploader from "./controls/Uploader";
import * as constants from "../constants";

const dateFormatter = new DateFormatter();
const agentsRequests = new AgentsRequests();

class DocumentsView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataEmpty: "hidden",
      hidden: "hidden",
      isLoaded: true,
      documentsData: [],
      isOpen: false,
      filteredDocumentsData: [],
      fromDate: localStorage.getItem("t4StartDate"),
      toDate: localStorage.getItem("t4EndDate"),
      isOpen: false,
      expanded: "",
      value: "",
      background: "hidden",
    };
    this.handleDateBox = this.handleDateBox.bind(this);
  }

  componentDidMount() {
    this.setState({
      hidden: "",
      isLoaded: false,
    });
    let startDate = dateFormatter.formatterTwo(
      localStorage.getItem("t4StartDate")
    );
    let endDate = dateFormatter.formatterTwo(localStorage.getItem("t4EndDate"));
    this.agentDocumentsFn(startDate, endDate);
  }

  agentDocumentsFn(startDate, endDate) {
    agentsRequests.agentDocuments(startDate, endDate).then((result) => {
      let documentsData = result;
      if (documentsData !== "error") {
        this.setState(
          {
            documentsData: documentsData,
            filteredDocumentsData: documentsData,
          },
          function () {
            this.setState({
              hidden: "hidden",
              isLoaded: true,
            });
          }
        );
      } else {
        this.setState({ dataEmpty: "" });
      }
    });
  }

  searchHandeler() {
    var searchValue = document.getElementById("search").value;
    this.setState({ value: searchValue });
    let data = this.state.documentsData;

    if (searchValue != "") {
      data = data.filter((object) =>
        object.docName.toUpperCase().includes(searchValue.toUpperCase())
      );
    }

    if (data.length === 0) {
      this.setState({ dataEmpty: "" });
    } else {
      this.setState({ dataEmpty: "hidden" });
    }

    this.setState({ filteredDocumentsData: data });
  }

  dateStr(date) {
    let uDate = moment(date, "DD/MM/YYYY");
    return "Uploaded on: " + moment(uDate).format("DD-MM-YYYY");
  }

  handleDateBox(e, name) {
    let selectedDate = e;
    let fromDate = new Date(dateFormatter.formatterThree(this.state.fromDate));
    let toDate = new Date(dateFormatter.formatterThree(this.state.toDate));
    let sDate = new Date(dateFormatter.formatterThree(selectedDate));

    if (name === "FromDate") {
      let startDate = dateFormatter.formatterTwo(sDate);
      let endDate = dateFormatter.formatterTwo(toDate);
      localStorage.setItem("t4StartDate", startDate);
      localStorage.setItem("dateRangeLabel4", "Custom Range");
      this.setState(
        {
          fromDate: selectedDate,
          isOpen: false,
          expanded: "",
          background: "hidden",
        },
        function () {
          //console.log(this.state.fromDate);
          this.agentDocumentsFn(startDate, endDate);
        }
      );
    } else if (name === "ToDate") {
      let startDate = dateFormatter.formatterTwo(fromDate);
      let endDate = dateFormatter.formatterTwo(sDate);
      localStorage.setItem("t4EndDate", endDate);
      localStorage.setItem("dateRangeLabel4", "Custom Range");
      this.setState(
        {
          toDate: selectedDate,
          isOpen: false,
          expanded: "",
          background: "hidden",
        },
        function () {
          this.agentDocumentsFn(startDate, endDate);
        }
      );
    }
  }

  uploaderPanel() {
    let expanded = this.state.expanded;
    if (expanded) {
      this.setState({
        isOpen: false,
        expanded: "",
        background: "hidden",
      });
    } else {
      this.setState({
        isOpen: true,
        expanded: "yes",
        background: "",
      });
    }
  }

  renderTiles() {
    let documentsData = this.state.filteredDocumentsData;
    if (documentsData.length >= 1) {
      return (
        <div className="bg-gray-100 h-70vh  shadow rounded-lg overflow-auto">
          <div className="p-4 mt-5 grid grid-cols-1 gap-5 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-5 ">
            {documentsData.map((object, index) => (
              <div key={"id" + index}>
                <DataTile
                  id={"id" + index}
                  svgType={object.docName.slice(
                    (Math.max(0, object.docName.lastIndexOf(".")) || Infinity) +
                      1
                  )}
                  title={object.docName}
                  description2={
                    <div className="grid grid-cols-7">
                      <div className="col-span-6 text-xxs">
                        <p> {"Size: " + object.docSize}</p>
                      </div>
                      <div className="col-span-1 pt-3">
                        <a
                          className="hover:text-blue-700 text-sm text-gray-600 hover:font-semibold cursor-pointer flex justify-end"
                          href={object.downloadLink}
                        >
                          <svg
                            className="w-4 h-4"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                            />
                          </svg>
                        </a>
                      </div>
                    </div>
                  }
                  description1={
                    <div className="text-xxs text-gray-500">
                      {this.dateStr(object.uploadDate)}
                    </div>
                  }
                />
              </div>
            ))}
          </div>
        </div>
      );
    } else {
      return (
        <div
          className={
            this.state.dataEmpty +
            " text-gray-600 w-full flex justify-center mt-20"
          }
        >
          <h1>
            There is No Data Available to Display, please reset filters if any
            applied.
          </h1>
        </div>
      );
    }
  }

  upload(file, docTypeId) {
    this.setState({ uploadFile: file });
  }

  async uploadFile() {
    var file = this.state.uploadFile;
    const objectType = 4; //proposal type
    const objectID = 260; //Applicant Id
    if (file) {
      if (file.length > 0) {
        this.setState({
          hidden: "",
          isLoaded: false,
        });
        let form = new FormData();

        form.append("file", file[0]);
        form.append("objectId", objectID);
        form.append("objectType", objectType);
        form.append("isPublic", 1);

        try {
          Axios.post(constants.BASEURL + "Document", form).then((result) => {
            if ((result.data.sucess = false)) {
              alert("Fail");
            } else {
              this.setState(
                {
                  hidden: "hidden",
                  isLoaded: true,
                  isOpen: false,
                  background: "hidden",
                },
                function () {
                  alert("Success");
                }
              );
            }
          });
        } catch (e) {
          alert("Error while uploading ");
        }
      }
    }
  }

  render() {
    return (
      <div className="relative mt-6 align-middle">
        <span
          className={
            this.state.hidden +
            " z-30 fixed top-10 left-0 right-0 bottom-0 h-full w-full bg-white opacity-25"
          }
        />
        <Loader loaded={this.state.isLoaded}></Loader>
        <div className="flex rounded-md p-4 mb-4 md:mb-8 w-full grid grid-cols-1 gap-5 md:grid-cols-2 xl:grid-cols-6 bg-gray-100">
          <div className="xl:col-span-2">
            <SearchBox
              id="search"
              label={"Search For Document"}
              svgType={"document"}
              placeholder={"Name"}
              value={this.state.value}
              searchHandler={() => {
                this.searchHandeler();
              }}
            />
          </div>

          <div className="xl:col-span-1">
            <DateBox
              id={"Ud1"}
              label="Upload Date From"
              //minDate={moment().add(0, "days").toDate()}
              onChange={this.handleDateBox}
              name={"FromDate"}
              value={this.state.fromDate ? new Date(this.state.fromDate) : ""}
            />
          </div>
          <div className="xl:col-span-1">
            <DateBox
              id={"Ud1"}
              label="Upload Date Till"
              //minDate={moment().add(0, "days").toDate()}
              onChange={this.handleDateBox}
              name={"ToDate"}
              value={this.state.toDate ? new Date(this.state.toDate) : ""}
              //onChange={this.handleChange}
            />
          </div>
        </div>

        <div>
          <span
            onClick={() => this.uploaderPanel()}
            className="absolute z-20  p-3 md:py-2 h-7 right-0 bg-gray-300 cursor-pointer"
          >
            +
          </span>
        </div>

        <div
          className={
            (this.state.isOpen ? "" : "hidden") +
            " z-40 child w-70per h-60per bg-yellow-100 p-6"
          }
        >
          <div className="h-80percent">
            <Uploader
              onUpload={(file, docTypeId) => {
                this.upload(file, docTypeId);
              }}
              docTypeId={"AgentDocuments"}
            />
          </div>
          <div className="sm:border-t sm:border-gray-200 sm:pt-5 w-full flex justify-center">
            <button
              id="submit"
              type="button"
              onClick={() => {
                this.uploadFile();
              }}
              className="inline-flex justify-center py-1 px-4 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
            >
              Submit
            </button>
          </div>
        </div>

        <div className="">
          <span
            onClick={() => this.uploaderPanel()}
            className={
              this.state.background +
              " z-30 fixed top-10 left-0 right-0 bottom-0 h-full w-full bg-gray-400 opacity-75"
            }
          />
        </div>

        {this.renderTiles()}
      </div>
    );
  }
}

export default DocumentsView;
