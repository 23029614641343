import React, { Component } from "react";
import Icon from "./Icon";

class ListItem extends Component {
  state = {};
  render() {
    const { listItem } = this.props;
    return (
      <React.Fragment>
        <div className="mr-6 flex items-center text-sm leading-5 text-gray-500">
          {listItem.icon ? <Icon src={listItem.icon} /> : ""}
          {listItem.itemText}
        </div>
      </React.Fragment>
    );
  }
}

export default ListItem;
