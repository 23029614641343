import React, { Component } from "react";
import { AgentsRequests } from "../../apiRequests/AgentsRequests";
import FormLabel from "../controls/FormLabel";

const agentsRequests = new AgentsRequests();

class Sms extends Component {
  state = { number: this.props.number };
  sendMessage() {
    const callBack = this.props.callBack;
    let number = this.props.number.replace(/\D/g, "");
    let message = document.getElementById("message").value;
    if (/\S/.test(message)) {
      agentsRequests.sendSms(number, message).then((result) => {
        if (result.startsWith("id:")) {
          alert("Message Sent Successfully");
          callBack("success");
        } else {
          console.log(result);
          alert("An error occured while sending SMS. " + result);
        }
      });
    } else {
      alert("Enter Message");
    }
  }
  render() {
    return (
      <div className="bg-gray-200 mobile w-30per ">
        <div className="w-full flex justify-center">
          <div className="mx-4 mt-4 p-2 bg-white w-full h-24 rounded-2xl rounded-b-none">
            <div className="w-full p-2 border border-green-400 rounded-full">
              <FormLabel name={"SMS"} text={"To: " + this.props.number} />
            </div>
          </div>
        </div>
        <div className="w-full flex justify-center">
          <textarea
            rows="10"
            className="mx-4 w-full focus:outline-none p-2"
            id="message"
          ></textarea>
        </div>
        <div className="w-full flex justify-center p-4">
          <button
            className="w-10 h-10 rounded-full items-center text-white text-semibold bg-black flex justify-center border-2 rounded-full focus:outline-none hover:border-gray-500"
            onClick={() => this.sendMessage()}
          >
            <svg
              className="w-6 h-6"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zM8 7a1 1 0 00-1 1v4a1 1 0 001 1h4a1 1 0 001-1V8a1 1 0 00-1-1H8z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>
      </div>
    );
  }
}

export default Sms;
