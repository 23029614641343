// In this component we have imported React, ListItem,CardHeader,
// Link,and ListItemDetail component.
import React, { Component } from "react";
import ListItem from "./ListItem";
import CardHeader from "./CardHeader";
import { Link } from "react-router-dom";
import Image from "./Image";
import ListItemDetail from "./ListItemDetail";

class WideList extends Component {
  state = {};
  componentDidMount() {
    // console.log("Did mount:" + this.props.listData.itemList);
  }
  componentWillMount() {
    // console.log("Did mount:" + this.props.listData.itemList);
  }
  // All the following code is used to render all the data which has been
  // passed to WideList component with the hep of imported components.
  renderContents(headingIcon, headingText, itemList) {
    return (
      <div className="px-4 py-4 sm:px-6">
        <div className="flex items-center justify-between">
          <div className="text-sm leading-5 flex font-medium text-blue-600 truncate">
            {headingIcon ? (
              <Image src={`${process.env.PUBLIC_URL}headingIcon`} />
            ) : (
                ""
              )}{" "}
            {headingText}
          </div>
          <div className="ml-2 flex-shrink-0 flex">
            {/* <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
      
    </span> */}
          </div>
        </div>

        <div className="mt-2 sm:flex sm:justify-between">
          <div className="sm:flex">
            {itemList.map((subItem) => (
              <ListItem listItem={subItem} />
            ))}
          </div>
        </div>
      </div>
    );
  }

  renderExpandableContents(
    headingIcon,
    headingText,
    itemList,
    showDetail,
    buttonIcon,
    buttonText,
    buttonFunction,
    clickEvent
  ) {
    return (
      <div className="px-4 py-4 sm:px-6">
        <div className="flex items-center justify-between">
          <div
            className="text-sm leading-5 flex font-medium text-blue-600 truncate"
            onClick={clickEvent}
          >
            <span className="mr-2 h-7 flex items-center">
              <svg
                className={
                  (showDetail ? "-rotate-180" : "-rotate-0") +
                  " h-4 w-4 transform"
                }
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 9l-7 7-7-7"
                />
              </svg>
            </span>
            {headingIcon ? (
              <Image src={`${process.env.PUBLIC_URL}headingIcon`} />
            ) : (
                ""
              )}{" "}
            {headingText}
          </div>
          <div className="ml-2 flex-shrink-0 flex">
            <span className="inline-flex rounded-md shadow-sm">
              <button
                onClick={buttonFunction}
                type="button"
                className={
                  "inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150" +
                  (buttonFunction || "rounded opacity-50 cursor-not-allowed")
                }
              >
                <svg
                  className="-ml-0.5 mr-2 h-4 w-4"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path d={buttonIcon} />
                </svg>
                {buttonText}
              </button>
            </span>
          </div>
        </div>
        <div className="mt-2 sm:flex sm:justify-between">
          <div className="sm:flex">
            {itemList.map((subItem) => (
              <ListItem listItem={subItem} />
            ))}
          </div>
        </div>
      </div>
    );
  }

  renderLink(
    controlId,
    headingText,
    itemList,
    linkTarget,
    headingIcon,
    clickEvent,
    subComponentData,
    showDetail,
    buttonIcon,
    buttonText,
    buttonFunction
  ) {
    // console.log(linkTarget.indexOf("amazonaws.com") + " = " + clickEvent)
    if (linkTarget.indexOf("amazonaws.com") > 0 || clickEvent) {
      if (clickEvent) {
        return (
          <li key={controlId}>
            <a className="cursor-pointer block hover:bg-gray-100 focus:outline-none focus:bg-gray-50 transition duration-150 ease-in-out">
              {this.renderExpandableContents(
                headingIcon,
                headingText,
                itemList,
                showDetail,
                buttonIcon,
                buttonText,
                buttonFunction,
                clickEvent
              )}
            </a>
            <ListItemDetail
              showControl={showDetail}
              itemData={subComponentData}
            ></ListItemDetail>
          </li>
        );
      } else {
        return (
          <li key={controlId}>
            <a
              href={linkTarget || "#"}
              className="block hover:bg-gray-100 focus:outline-none focus:bg-gray-50 transition duration-150 ease-in-out"
              target="_blank"
            >
              {this.renderContents(headingIcon, headingText, itemList)}
            </a>
          </li>
        );
      }
    } else {
      return (
        <li key={controlId}>
          <Link
            to={linkTarget || "#"}
            className="block hover:bg-gray-100 focus:outline-none focus:bg-gray-50 transition duration-150 ease-in-out"
          >
            {this.renderContents(headingIcon, headingText, itemList)}
          </Link>
        </li>
      );
    }
  }
  render() {
    // console.log("WIde LIst Props: " + this.props.listData.itemList);
    const { cardHeaderSettings, listData } = this.props;
    return (
      <div className="mb-4">
        <div className="bg-white shadow overflow-hidden sm:rounded-md">
          <CardHeader settings={cardHeaderSettings} />
          <ul>
            {listData.map(
              ({
                controlId,
                headingText,
                itemList,
                linkTarget,
                headingIcon,
                clickEvent,
                subComponentData,
                showDetail,
                buttonIcon,
                buttonText,
                buttonFunction,
              }) =>
                this.renderLink(
                  controlId,
                  headingText,
                  itemList,
                  linkTarget,
                  headingIcon,
                  clickEvent,
                  subComponentData,
                  showDetail,
                  buttonIcon,
                  buttonText,
                  buttonFunction
                )
            )}
          </ul>
        </div>
      </div>
    );
  }
}

export default WideList;
